<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4043_5241)">
      <path
        d="M14 2.5H13.3334V1.16663C13.3334 0.798706 13.0347 0.5 12.6666 0.5H12C11.632 0.5 11.3334 0.798706 11.3334 1.16663V2.5H4.66663V1.16663C4.66663 0.798706 4.36804 0.5 4 0.5H3.33337C2.96533 0.5 2.66663 0.798706 2.66663 1.16663V2.5H2C0.897339 2.5 0 3.39734 0 4.5V14.5C0 15.6027 0.897339 16.5 2 16.5H14C15.1027 16.5 16 15.6027 16 14.5V4.5C16 3.39734 15.1027 2.5 14 2.5ZM14 13.8334C14 14.2007 13.7007 14.5 13.3334 14.5H2.66663C2.29932 14.5 2 14.2007 2 13.8334V8.5H14V13.8334Z"
        fill="#868D9C"
      />
    </g>
    <defs>
      <clipPath id="clip0_4043_5241">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
